@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@700&family=Dosis:wght@300;400;500;600;700&family=Edu+TAS+Beginner&family=Montserrat:wght@300;400;500;900&display=swap');

* {
  font-family: 'Dosis', sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}


body {
  background-image: url(./assets/bg-tigolos.jpeg);
  background-size: cover;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}