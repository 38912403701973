.user {
  height: auto;
  margin-bottom: 3rem;
}

.c-appointment-user {
  margin: 0rem auto;
  max-width: 75rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: center;
}

.appointment-user {
  position: relative;
  border-radius: 1rem;
  margin-top: 10rem;
  padding: 4rem;
  background-image: url(../assets/bg-text-branca.jpeg);
  height: auto;
}

.c-ressesion {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
  margin-bottom: 7rem;
  font-weight: 900;
  font-size: 1.5rem;
}

.ressesion {
  margin: 0px 25px 30px;
}

.ressesion p {
  margin-top: 2rem;
  padding: 0px;
  font-size: 1.14rem;
  font-weight: 500;
  color: gray;
  text-align: justify;
  display: flex;
  justify-content: center;
}

.logout {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 5px;
  font-weight: 900;
  font-size: 19px;
  color: #ff9500;
}

.back {
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: 5px;
  font-weight: 900;
  font-size: 19px;
  color: #ff9500;
}

.c-appointment-btn {
  display: flex;
  border-radius: 0.4rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.appointment {
  background: #d87f01;
  width: max-content;
  padding: 0.7rem 0.3rem;
}

.appointment-btn {
  color: white;
  padding: 0.5rem 1.7rem;
  bottom: 2rem;
}

/*  MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
  .user {
    height: auto;
  }

  .c-appointment-user {
    padding: 0rem 2.53rem;
  }

  .appointment-user {
    padding: 3rem 1rem;
    padding-bottom: 6rem;
    margin-bottom: 3rem;
  }

  .c-ressesion {
    bottom: 210px;
    right: 30px;
    left: 30px;
  }
}

/*  MEDIA QUERIES (SMAIL DEVICES) */

@media screen and (max-width: 600px) {
  .user {
    height: auto;
  }
  .c-appointment-user {
    padding: 0rem 1.53rem;
  }

  .appointment-user {
    margin-top: 8rem;
    padding: 3rem 0rem;
  }

  .c-ressesion {
    bottom: 100px;
    right: 25px;
    left: 25px;
  }
  .c-appointment-btn {
    gap: 1rem;
  }
  .appointment-btn {
    width: auto;
  }
}

@media screen and (max-width: 360px) {
  .c-appointment-user {
    padding: 0rem 1.4rem;
  }
  .appointment-btn {
    width: auto;
    padding: 0.5rem;
  }
}
